<template>
  <aside id="default-sidebar" class="fixed top-16 sm:top-20 left-0 z-40 w-64 h-screen transition-transform -translate-x-full sm:translate-x-0" aria-label="Sidebar">
    <div class="h-full px-4 py-8 overflow-y-auto bg-white">
      <div class="flex space-x-2 items-center">
        <img :src="sessionStore.user.image" :alt="sessionStore.user.name" class="rounded-full w-16"/>
        <div>
          <div class="break-all font-bold text-gray-700">{{ sessionStore.user.name }}</div>
          <div class="mt-2 font-bold text-primary">Free user</div>
        </div>
      </div>

      <div class="my-8">
        <hr class="border-solid border-gray-300">
      </div>

      <ul class="space-y-2 font-medium">
        <li>
          <router-link
            :to="{ name: 'MyAccount' }"
            class="link-item"
            :class="{'link-item--active': route.name === 'MyAccount' }"
          >
            <i class="fa-solid fa-user w-5 h-5 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white"></i>
            <span>My account</span>
          </router-link>
        </li>

        <li>
          <router-link
            :to="{ name: 'MyTask' }"
            class="link-item"
            :class="{'link-item--active': route.name === 'MyTask' }"
          >
            <i class="fa-solid fa-clock w-5 h-5 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white"></i>
            <span>Recent tasks</span>
          </router-link>
        </li>

        <li>
          <hr class="border-solid border-gray-300">
        </li>

        <li>
          <button
            class="link-item"
            @click="onSignOut"
          >
            <i class="fa-solid fa-right-from-bracket"/>
            <span>Sign out</span>
          </button>
        </li>
      </ul>
    </div>
  </aside>
</template>

<script setup lang="ts">
import { useRoute } from "vue-router";
import { useSessionStore } from '$/stores/session'
import { useFlowbite } from '$/composables/useFlowbite'

useFlowbite(['drawers'])

const route = useRoute()
const sessionStore = useSessionStore()

const onSignOut = function () {
  sessionStore.signOut().then(() => {
    window.location.href = '/'
  })
}
</script>
