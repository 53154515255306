<template>
  <AppNotice notice-type="alert" class="empty:hidden">
    <div v-if="invalidFileCount">You can only upload up to {{ featureStore.featureLimit.max_file_count }} files at once. Please reduce the number of files and try again.</div>
    <div v-if="invalidFileSizeIndexes.length > 0">The file size exceeds the limit of {{ featureStore.featureLimit.max_file_size }} MB. Please upload a smaller file.</div>
    <div v-if="invalidFileDimensionIndexes.length > 0">The file dimensions exceed the limit of {{ featureStore.featureLimit.max_width }}x{{ featureStore.featureLimit.max_height }} pixels. Please resize your image and try again.</div>
  </AppNotice>
  <div v-if="invalid">
    <AuthModalTrigger>
      Log in to increase your upload limit and unlock additional features!
    </AuthModalTrigger>
  </div>
</template>

<script setup lang="ts">
import { useFeatureStore } from '$/stores/feature'
import AppNotice from "&/AppNotice.vue";
import AuthModalTrigger from "&/auth/AuthModalTrigger.vue";

const featureStore = useFeatureStore()

withDefaults(defineProps<{
  invalid?: boolean
  invalidFileCount?: boolean
  invalidFileSizeIndexes?: any
  invalidFileDimensionIndexes?: any
}>(), {
  invalid: false,
  invalidFileCount: false,
  invalidFileSizeIndexes: [],
  invalidFileDimensionIndexes: [],
})
</script>
