<template>
  <div
    id="modalFeatureProgress"
    tabindex="-1"
    aria-hidden="true"
    class="hidden overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full"
  >
    <div class="relative p-4 w-full max-w-xl max-h-full">
      <div class="relative bg-white rounded-lg shadow dark:bg-gray-700 p-4">
        <div class="text-xl md:text-3xl text-center font-semibold">
          {{ title }}
        </div>

        <div v-if="estimateSeconds" class="flex flex-col space-y-4 justify-center items-center mt-8">
          <div class="">Estimated Progress</div>
          <AppCircleProgress :duration="estimateSeconds"/>
        </div>
        <div v-else class="loader mx-auto mt-8"></div>

        <div
          class="text-primary font-bold text-lg sm:text-xl text-center mt-6 flex space-y-2 items-center justify-center"
        >
          <div id="progressMessage" class="">Please wait a few seconds...</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { onMounted, ref } from 'vue'
import { useFeatureStore } from '$/stores/feature'
import { Modal } from 'flowbite'
import AppCircleProgress from "&/AppCircleProgress.vue";

const featureStore = useFeatureStore()
const title = ref('')
const estimateSeconds = ref(null)

switch (featureStore.feature.slug) {
  case 'remove-background':
    title.value = 'Removing background'
    break
  case 'upscale-restore-image':
    title.value = 'Upscale and restore quality'
    break
  case 'colorize-black-and-white-photo':
    title.value = 'Colorizing'
    break
  case 'blur-background':
    title.value = 'Blurring background'
    break
  case 'blur-face':
    title.value = 'Blurring face'
    break
  case 'image-shadow-generator':
    title.value = 'Generating shadow'
    break
  case 'crop-image':
    title.value = 'Cropping'
    break
  case 'compress-image':
    title.value = 'Compressing'
    break
  case 'resize-image':
    title.value = 'Resizing'
    break
  case 'convert-image':
    title.value = 'Converting'
    break
  case 'photo-to-anime-converter':
    title.value = 'Turning photo into anime style'
    break
  case 'rotate-image':
    title.value = 'Rotating'
    break
}

onMounted(() => {
  createModal()
})

function createModal() {
  featureStore.progressModal = null

  const $modal = document.getElementById('modalFeatureProgress')
  const modalOptions = {
    backdrop: 'static'
  }

  featureStore.progressModal = new Modal($modal, modalOptions)
}
</script>
