<template>
  <div class="px-4 lg:px-0">
    <div class="text-center">
      <h2 class="gradient-title">Popular Tools</h2>
    </div>

    <div class="mt-16 mx-auto max-w-screen-xl">
      <div class="grid grid-cols-12 gap-6 lg:gap-4">
        <FeatureItem
          v-for="feature in featureStore.popularFeatures"
          :key="feature.id"
          :feature="feature"
          modern
          class="col-span-12 sm:col-span-6 md:col-span-4 lg:col-span-3"
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useFeatureStore } from '$/stores/feature'
import FeatureItem from "&/FeatureItem.vue";

const featureStore = useFeatureStore()
</script>
