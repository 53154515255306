<template>
  <div
    :data-tooltip-target="`tooltip-${image.name}`"
    class="relative shadow-lg rounded-lg flex flex-col space-y-1 w-full p-2 border border-solid over:border-sky-300 bg-white border-white"
    :class="{'opacity-60': !processed && featureStore.processing, 'invalid': invalid, 'error': errorOccurred }"
  >
    <div
      :id="`tooltip-${image.name}`"
      role="tooltip"
      class="absolute z-10 invisible inline-block px-3 py-2 text-sm font-medium text-white transition-opacity duration-300 bg-gray-900 rounded-lg shadow-sm opacity-0 tooltip dark:bg-gray-700"
    >
      <span>{{ image.name }} ({{ imageSize }})</span>
      <div class="tooltip-arrow" data-popper-arrow></div>
    </div>

    <div
      v-if="featureStore.processing && !processed"
      class="absolute z-50 top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
      <div class="loader2"></div>
    </div>

    <div
      v-if="errorOccurred"
      class="w-full absolute z-10 top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 bg-red-800 bg-opacity-90 text-red-100 p-4">
      Sorry, we couldn't process this image. Please try again later.
    </div>

    <div>
      <div
        class="cursor-pointer max-w-max ml-auto text-3xl hover:text-rose-600"
        :class="{'invisible': featureStore.processing }"
        @click="removeImage">
        <i class="fa-regular fa-circle-xmark"></i>
      </div>
    </div>

    <div v-if="processed && !errorOccurred">
      <AppComparisonSlider :first="getImageURL(image)" :second="featureStore.results.find(i => Number(i.image_index) === imageIndex).url" />
    </div>

    <div
      class="flex-grow inline-flex items-center justify-center"
      :class="{'hidden': processed && !errorOccurred}"
    >
      <div class="transparent-wrap transition" :style="imageStyle">
        <img :src="imageURL" :alt="image.name" class="" @load="handImageLoad" />
      </div>
    </div>

    <div class="text-gray-600 text-center break-all text-sm line-clamp-1">
      <span>{{ image.name }}</span>
      <span class="ml-1">({{ imageSize }})</span>
    </div>

    <section>
      <div class="flex flex-wrap justify-center items-center">
        <div v-if="featureStore.feature.slug === 'resize-image'" class="flex flex-wrap justify-center items-center">
          <div class="badge badge--dark">
            <span>{{ imageDimensions.width }}</span>
            <span class="mx-1">x</span>
            <span>{{ imageDimensions.height }}</span>
          </div>
          <span class="text-sm mx-1">
            <i class="fa-solid fa-arrow-right"></i>
          </span>
          <div class="badge badge--green">
            <span>{{ featureStore.options[featureStore.feature.slug].resizeWidth }}</span>
            <span class="mx-1">x</span>
            <span>{{ featureStore.options[featureStore.feature.slug].resizeHeight }}</span>
          </div>
        </div>

        <div v-else class="badge badge--lg badge--dark">
          <span>{{ imageDimensions.width }}</span>
          <span class="mx-1">x</span>
          <span>{{ imageDimensions.height }}</span>
        </div>
      </div>

      <div v-if="featureStore.feature.slug === 'convert-image'" class="mt-2 flex flex-wrap justify-center items-center">
        <span class="badge badge--lg badge--dark">{{ getImageExtension(image) }}</span>
        <span class="text-sm mx-1">
          <i class="fa-solid fa-arrow-right"></i>
        </span>
        <span class="badge badge--lg badge--green">{{ featureStore.options[featureStore.feature.slug].convertTo }}</span>
      </div>
    </section>
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted, watch, computed } from 'vue'
import { useRoute, useRouter } from "vue-router";
import { useFeatureStore } from '$/stores/feature'
import { getImageURL, getImageExtension, formatBytes } from '$/utils'
import { useFlowbite } from '$/composables/useFlowbite'
import AppComparisonSlider from "&/AppComparisonSlider.vue";

useFlowbite(['tooltips'])

const featureStore = useFeatureStore()
const imageURL = ref('')
const imageDimensions = ref({})
const imageSize = ref('')
const imageStyle = ref('')

const props = withDefaults(defineProps<{
  image: object
  imageIndex: number
  invalid?: boolean
}>(), {
  invalid: false
})

const route = useRoute()
const router = useRouter()
const processed = computed(() => featureStore.results.map(i => Number(i.image_index)).includes(props.imageIndex))
const errorMessage = computed(() => featureStore.results.find(i => Number(i.image_index) === props.imageIndex)?.error)
const errorOccurred = computed(() => processed.value && errorMessage.value )

watch(
  () => featureStore.options['rotate-image'].rotateAngle,
  (angle) => {
    if (featureStore.feature.slug === 'rotate-image') {
      imageStyle.value = `
      transform: rotate(${angle}deg);
      -webkit-transform: rotate(${angle}deg);
      -moz-transform: rotate(${angle}deg);
      -o-transform: rotate(${angle}deg);
      -ms-transform: rotate(${angle}deg);
    `
    }
  },
  { immediate: true }
)

onMounted(() => {
  if (props.image) {
    imageURL.value = URL.createObjectURL(props.image)
  }
})

function handImageLoad(event) {
  imageDimensions.value.width = event.target.naturalWidth
  imageDimensions.value.height = event.target.naturalHeight
  imageSize.value = formatBytes(props.image.size)
}

function removeImage() {
  featureStore.images = featureStore.images.filter((e) => e !== props.image)
  if (featureStore.images.length === 0) {
    router.replace({ query: { ...route.query, task: undefined, keep_uploaded: undefined }})
  }
}
</script>

<style scoped lang="scss">
.invalid {
  border-color: red !important;
  background-color: #fecaca !important;
}

.error {
  border-color: red !important;
}
</style>
