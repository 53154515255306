<template>
  <div>
    <div class="flex flex-col space-y-8 items-center justify-center">
      <AppLogo v-if="!simple"/>
      <h1 class="gradient-title">Log In to Your Account</h1>
    </div>

    <div class="mt-12 max-w-[400px] mx-auto">
      <form class="default-form" @submit.prevent="onSignIn">
        <div>
          <AppInputField v-model="form.email" label="Email" placeholder="Email" type="email" autofocus required>
            <i class="fa-solid fa-envelope"></i>
          </AppInputField>
        </div>

        <div>
          <AppInputField v-model="form.password" label="Password" placeholder="Password" type="password" required>
            <i class="fa-solid fa-key"></i>
          </AppInputField>
        </div>

        <div class="flex justify-center">
          <button :disabled="store.sending" type="submit" class="btn btn--lg btn--primary">
            <i class="fa-solid fa-right-to-bracket"></i>
            <span>Sign in</span>
          </button>
        </div>

        <div class="flex flex-col space-y-4 mt-8">
          <router-link
            :to="{ name: 'ForgotPassword' }"
            class="link text-primary font-bold"
          >Forgot your password?</router-link
          >

          <div>
            <span class="mr-1">Didn't receive the confirmation email?</span>
            <router-link
              :to="{ name: 'ResendConfirmationEmail' }"
              class="link text-primary font-bold"
            >Resend it</router-link
            >
          </div>

          <div v-if="!simple">
            <span class="mr-1">Don't have an account yet?</span>
            <router-link
              :to="{ name: 'SignUp' }"
              class="link text-primary font-bold"
            >Create an account</router-link
            >
          </div>
        </div>
      </form>

      <hr class="my-6 border border-solid border-gray-300" />
      <div class="">
        <OAuth/>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { reactive } from 'vue'
import { useAuth } from '$/composables/useAuth'

const { store, sessionStore, pushError, OAuth, AppInputField, AppLogo } = useAuth()

withDefaults(
  defineProps<{
    simple?: boolean
  }>(),
  {
    simple: false
  }
)

const form = reactive({
  email: '',
  password: ''
})

const onSignIn = function() {
  store.sending = true
  sessionStore
    .signIn(form)
    .then(() => {
      window.location.href = '/'
    })
    .catch(err => {
      store.sending = false
      let error = err?.response?.data?.errors?.join('\n') || err?.message
      pushError(error)
    })
}
</script>
