<template>
  <div class="grid grid-cols-12 h-full mx-auto max-w-[1920px]">
    <main class="col-span-12 lg:col-span-9 h-full bg-zinc-100 pt-8 pb-32">
      <div class="flex flex-wrap lg:flex-nowrap justify-between p-4 lg:p-6">
        <div class="lg:max-w-[70%] mr-8 mb-8 lg:mb-0">
          <h1 class="gradient-title mb-4">{{ featureStore.feature.name }}</h1>
          <div class="text-base">{{ featureStore.feature.description }}</div>

          <div class="mt-8 flex flex-col space-y-8">
            <AppImageValidation
              :invalid="invalid"
              :invalid-file-count="invalidFileCount"
              :invalid-file-size-indexes="invalidFileSizeIndexes"
              :invalid-file-dimension-indexes="invalidFileDimensionIndexes"
            />
          </div>
        </div>
        <AppImageUploader
          v-if="featureStore.images.length < featureStore.featureLimit.max_file_count"
          :disabled="disabled"
        />
      </div>

      <slot>
        <div class="mt-4 mx-auto max-w-screen-lg px-2">
          <div
            class="grid grid-cols-[repeat(auto-fit,_95%)] sm:grid-cols-[repeat(auto-fit,_45%)] lg:grid-cols-[repeat(auto-fit,_31%)] xl:grid-cols-[repeat(auto-fit,_23%)] justify-center gap-4"
            :style="imageWrapStyle"
          >
            <AppImagePreview
              v-for="(image, index) in featureStore.images"
              :key="image"
              :image="image"
              :image-index="index"
              :invalid="invalidFileSizeIndexes.includes(index) || invalidFileDimensionIndexes.includes(index)"
            />
          </div>
        </div>
      </slot>
    </main>

    <aside
      class="z-50 lg:z-10 fixed lg:relative bottom-0 lg:h-full w-full shadow-md border-t-4 lg:border-0 col-span-12 lg:col-span-3 bg-white lg:border-l-2 border-solid border-gray-200 px-6 lg:py-16"
    >
      <div class="lg:sticky lg:top-36 flex flex-col">
        <div class="max-h-[200px] lg:max-h-full overflow-y-scroll mt-4 lg:mt-8 p-4">
          <slot name="aside"> </slot>
        </div>

        <div class="lg:order-first py-4 lg:py-0">
          <slot name="action">
            <div
              class="btn btn--lg btn--primary w-full"
              :class="{'btn--disabled': disabled}"
              @click="submit"
            >
              <div v-if="featureStore.processing" class="flex items-center justify-center">
                <AppSpinner/>
                <span class="">{{ processingText }}</span>
              </div>
              <div v-else class="flex items-center justify-center">
                <i class="fa-solid fa-bolt-lightning"></i>
                <span>{{ processText }}</span>
              </div>
            </div>
          </slot>
        </div>
      </div>
    </aside>
  </div>

  <ModalFeatureProgress />
</template>

<script setup lang="ts">
import { computed, watch, ref } from 'vue'
import { useStore } from '$/stores'
import { useFeatureStore } from '$/stores/feature'
import { useSubmit } from '$/composables/useSubmit'
import { useValidate } from '$/composables/useValidate'
import AppImageUploader from '&/AppImageUploader.vue'
import AppImagePreview from '&/AppImagePreview.vue'
import ModalFeatureProgress from '&/ModalFeatureProgress.vue'
import AppSpinner from "&/AppSpinner.vue";
import AppImageValidation from "&/AppImageValidation.vue";

const emit = defineEmits(['finished'])

const store = useStore()
const featureStore = useFeatureStore()
const { onSubmit } = useSubmit()
const { invalid, invalidFileCount, invalidFileSizeIndexes, invalidFileDimensionIndexes } = useValidate()

const disabled = computed(() => {
  return invalid.value || store.sending || featureStore.processing
})
const processText = computed(() => {
  if (featureStore.images.length > 1) {
    return `${featureStore.feature.action} ${featureStore.images.length} photos`
  } else {
    return featureStore.feature.action
  }
})
const processingText = computed(() => {
  if (featureStore.results.length > 0) {
    let percentage = Math.floor(featureStore.results.length / featureStore.images.length * 100)
    return `Processing... (${percentage}%)`
  } else {
    return 'Processing...'
  }
})

const imageWrapStyle = ref('')

function submit() {
  if (disabled.value) return

  onSubmit(() => emit('finished'))
}

watch(
  () => featureStore.images,
  () => {
    if (window.screen.width >= 1024) {
      let itemWidth = null
      switch (featureStore.images.length) {
        case 1:
          itemWidth = 60
          break
        case 2:
          itemWidth = 48
          break
        case 3:
          itemWidth = 30
          break
        default:
          itemWidth = 23
      }
      imageWrapStyle.value = `grid-template-columns: repeat(auto-fit,${itemWidth}%) !important`
    }
  },
  { immediate: true, deep: true }
)
</script>
