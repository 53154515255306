<template>
  <div
    v-show="!sessionStore.user"
    class=""
  >
    <AppNotice>
      <p class="font-semibold">
        <slot/>
      </p>
      <div class="mt-4 flex justify-center">
        <button
          class="btn btn--pink"
          type="button"
          @click="sessionStore.authModal.show()"
        >

          <i class="fa-solid fa-user-plus"></i>
          <span>Get Started – It's Free!</span>
        </button>
      </div>
    </AppNotice>
  </div>

</template>

<script setup lang="ts">
import { useSessionStore } from '$/stores/session'
import AppNotice from "&/AppNotice.vue";

const sessionStore = useSessionStore()
</script>
