<template>
  <div v-if="ready" class="feature">
    <FeatureMeta :feature="featureStore.feature" />

    <div v-if="featureStore.images.length === 0">
      <FeatureInitSection
        :feature="featureStore.feature"
        :intro-count="3"
        :sample-count="4"
      />
      <PageUpscale />
    </div>

    <div v-else class="h-full">
      <LayoutDefault @finished="onFinished">
        <template #aside>
          <div class="flex flex-col space-y-6 mt-8">
            <div class="flex flex-col space-y-2">
              <label for="upscale" class="input-label">Upscale</label>
              <div class="flex space-x-4 items-center">
                <label
                  v-for="upscale in [['200%', 2], ['400%', 4]]" :key="upscale[1]"
                  class="w-1/2 input-label icon-option flex space-x-2 items-center justify-center"
                  :class="{'icon-option--pro': upscale[1] === 4}"
                  :for="`upscale_${upscale[1]}`">
                  <input
                    :id="`upscale_${upscale[1]}`"
                    v-model="featureStore.options['upscale-restore-image'].upscale"
                    class="radio"
                    :value="upscale[1]"
                    type="radio"
                  />
                  <span>{{ upscale[0] }}</span>
                </label>
              </div>
            </div>
          </div>
        </template>
      </LayoutDefault>
    </div>
  </div>
</template>

<script setup lang="ts">
import { watch } from "vue";
import { useFeatureSetup } from '$/composables/useFeatureSetup'
import PageUpscale from '&/landing_pages/PageUpscale.vue'
import FeatureMeta from '&/FeatureMeta.vue'

const { ready, router, slug, sessionStore, featureStore, LayoutDefault, FeatureInitSection } = useFeatureSetup()

function onFinished() {
  router.push({ name: 'UpscaleResult' })
}

watch(
  () => featureStore.options[slug].upscale,
  (newVal, oldVal) => {
    if (newVal === 4 && featureStore.featureLimit.restrict_options.includes('upscale_4x')) {
      sessionStore.authModal.show()
      setTimeout(() => {
        featureStore.options[slug].upscale = oldVal
      }, 500)
    }
  }, { immediate: true }
)
</script>
