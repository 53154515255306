import { Dropdown } from "flowbite";
import {useNotification} from "@kyvg/vue3-notification";
import JSZip from 'jszip'
import saveAs from 'file-saver'
const { notify } = useNotification()

export function formatBytes(bytes,decimals) {
    if (bytes === 0) return '0 Bytes';
    let k = 1024,
        dm = decimals || 2,
        sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
        i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}

export function getCurrentFeatureSlug() {
    const urlObject = new URL(window.location.href);
    const pathSegments = urlObject.pathname.split('/').filter(Boolean);
    // Check if the last segment is a feature slug, otherwise check the second-to-last
    if (pathSegments.length === 1) {
        return pathSegments[0]; // Single segment path like "/remove-background"
    } else if (pathSegments.length > 1) {
        return pathSegments[pathSegments.length - 1]; // Last segment of paths like "/result/remove-background"
    }
    return null; // No valid segments
}

export function getImageURL(image) {
    return URL.createObjectURL(image)
}

export function getImageExtension(image) {
    switch(image.type) {
        case 'image/jpeg':
        case 'image/jpg':
            return 'JPG'
        case 'image/png':
            return 'PNG'
        case 'image/webp':
            return 'WEBP'
        case 'image/gif':
            return 'GIF'
        default:
            return image.type.split('/')[-1]
    }
}

export function downloadImage(url) {
    return new Promise((resolve, reject) => {
        // Extract the filename from the URL
        const filename = url.split('/').pop().split('?')[0];

        // Fetch the image as a blob
        fetch(url)
            .then(response => {
                if (!response.ok) {
                    throw new Error(`Failed to fetch ${url}: Status ${response.status}`);
                }
                return response.blob();
            })
            .then(blob => {
                // Create a new URL for the blob object
                const blobUrl = URL.createObjectURL(blob);

                // Create an 'a' element to initiate download
                const a = document.createElement("a");
                a.href = blobUrl;
                a.download = filename || "default-filename";

                // Append the 'a' element to the document body
                document.body.appendChild(a);

                // Trigger the download by simulating a click
                a.click();

                // Clean up: revoke the blob URL and remove the 'a' element after download
                setTimeout(() => {
                    URL.revokeObjectURL(blobUrl);
                    document.body.removeChild(a);
                    resolve(); // Resolve the promise after cleanup
                }, 100); // Delay removal for Firefox compatibility
            })
            .catch(error => {
                console.error('Error downloading the image:', error);
                reject(error); // Reject the promise on error
            });
    });
}

export function downloadImages(urls) {
    var zip = new JSZip();
    var zipFilename = "piceditor.io.zip";

    // Map each URL to a promise that resolves when the image has been fetched and added to the ZIP
    const promises = urls.map(url => {
        var filename = url.split('/').pop(); // Assumes that the URL ends with the filename
        return fetch(url)
            .then(response => {
                if (!response.ok) throw new Error('Network response was not ok');
                return response.blob();
            })
            .then(blob => {
                zip.file(filename, blob);
            })
            .catch(err => {
                console.error('Error downloading or adding image to ZIP:', err);
                throw err;  // Rethrow to ensure this error propagates up
            });
    });

    // Use Promise.all to wait for all fetches to complete
    return Promise.all(promises)
        .then(() => {
            // Only generate the ZIP file once all files have been successfully added
            return zip.generateAsync({type: 'blob'});
        })
        .then(content => {
            // Use FileSaver or similar to save the generated blob
            saveAs(content, zipFilename);
        })
        .catch(err => {
            console.error('Failed to download images or save ZIP:', err);
            throw err;  // Optional: Rethrow or handle error as needed
        });
}

export async function copyImage(url) {
    try {
        const response = await fetch(url);
        if (!response.ok) throw new Error('Failed to fetch the image');

        const imageBlob = await response.blob();
        const mimeType = 'image/png'; // Set to PNG for wider support

        // Create an offscreen canvas
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        const img = await createImageBitmap(imageBlob);

        // Set canvas size to the image size
        canvas.width = img.width;
        canvas.height = img.height;

        // Draw the image onto the canvas
        ctx.drawImage(img, 0, 0);

        // Convert the canvas to a blob
        const newBlob = await new Promise(resolve => canvas.toBlob(resolve, mimeType));

        // Create a new clipboard item
        const clipboardItem = new ClipboardItem({ [mimeType]: newBlob });
        await navigator.clipboard.write([clipboardItem]);
        notify({
            title: 'Copied image to clipboard',
            text: `<img src='${url}' alt="${url}" style="margin: auto; max-width: 180px;">`
        })
    } catch (error) {
        console.error('Failed to copy image:', error);
    }
}

export function copy(text, message) {
    navigator.clipboard.writeText(text)
    notify({
        title: message,
    })
}

export function hex2rbga(hex, opacity) {
    hex = hex.replace('#', '');
    let r = 0, g = 0, b = 0;

    if (hex.length === 3) {
        r = parseInt(hex[0] + hex[0], 16);
        g = parseInt(hex[1] + hex[1], 16);
        b = parseInt(hex[2] + hex[2], 16);
    } else if (hex.length === 6) {
        r = parseInt(hex.substring(0, 2), 16);
        g = parseInt(hex.substring(2, 4), 16);
        b = parseInt(hex.substring(4, 6), 16);
    }

    return `rgba(${r}, ${g}, ${b}, ${opacity})`;
}

export function closeDropdown(targetEl, triggerEl) {
    const $targetEl = document.getElementById(targetEl)
    const $triggerEl = document.getElementById(triggerEl);
    if ($triggerEl && $triggerEl) {
        const dropdown = new Dropdown($targetEl, $triggerEl)
        dropdown.hide()
    }
}

export function formatTimestamp(timestamp) {
    const date = new Date(timestamp);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    return `${year}-${month}-${day} ${hours}:${minutes}`;
}
