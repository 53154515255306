<template>
  <div class="grid grid-cols-2 gap-8 md:gap-0 h-full">
    <main class="col-span-2 md:col-span-1">
      <div class="py-8 mx-auto px-4 lg:px-12 h-full flex flex-col justify-center">
        <SignUpForm/>
      </div>
    </main>

    <aside class="col-span-2 md:col-span-1 bg-teal-50">
      <div class="py-8 mx-auto px-4 lg:px-12 h-full flex flex-col justify-center max-w-[640px]">
        <div>
          <img src="@/images/pages/auth_top.png" alt="Register to piceditor.io" class="w-2/3 mx-auto"/>
          <h2 class="hidden">{{ welcomeTexts[0] }}</h2>
          <div
            class="mt-12 font-extrabold text-transparent text-3xl bg-clip-text bg-gradient-to-r from-purple-400 to-pink-600"
          >
            {{ welcomeText }}
          </div>
        </div>
      </div>
    </aside>
  </div>
</template>

<script setup lang="ts">
import SignUpForm from "&/auth/SignUpForm.vue";

const welcomeTexts = [
  'Create Your Account and Start Editing Photos Online',
  'Unlock the full potential of your images - Join piceditor.io today!',
  'Ready to elevate your photo editing skills? Sign up for piceditor.io now!',
  'Start your journey to amazing photo edits - Sign up at piceditor.io!',
  'Explore advanced editing features exclusively on piceditor.io - Join now!',
  'Become a member of piceditor.io and transform your images effortlessly!',
  'Join our community of photo editing enthusiasts - Sign up and get started!',
  'Create stunning visuals with our easy-to-use tools - Sign up today!',
  'Sign up for piceditor.io and access premium editing features instantly!',
  'Step into the future of image editing - Create your account at piceditor.io now!',
  'Join piceditor.io and start creating professional-quality images today!'
]

const welcomeText = welcomeTexts[Math.floor(Math.random() * welcomeTexts.length)]
</script>
