<template>
  <ul class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
    <li v-for="feature in features">
      <router-link
        :to="url(feature.slug)"
        class="flex space-x-2 px-2 py-1 items-center border shadow hover:bg-cyan-50 transition"
      >
        <FeatureIcon :feature="feature" class="w-8"/>
        <div class="font-semibold">{{ feature.name }}</div>
      </router-link>
    </li>
  </ul>
</template>

<script setup lang="ts">
import FeatureIcon from '&/FeatureIcon.vue'

const props = defineProps<{
  features: any
  resultTaskId?: string
}>()

function url(slug) {
  if (props.resultTaskId) {
    return `/${slug}?task=${props.resultTaskId}`
  } else {
    return `/${slug}`
  }
}
</script>
