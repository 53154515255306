<template>
  <div class="mt-8 px-2 lg:px-0 flex flex-col space-y-8 lg:flex-row lg:space-x-8 lg:space-y-0 max-w-screen-2xl mx-auto justify-center">
    <div class="overflow-x-scroll">
      <table class="table table--stripped shadow-lg mx-auto">
        <tbody>
        <tr v-for="(result, index) in featureStore.results" :key="result.url">
          <td v-if="showOrigin" class="min-w-32">
            <div
              class="flex space-x-1 justify-between items-center">
              <img :src="getImageURL(featureStore.images[index])" :alt="result.filename" class="mx-auto max-h-16" />
              <i class="fa-solid fa-arrow-right"></i>
            </div>
          </td>

          <td
            :class="{'opacity-60': featureStore.processing}"
            class="min-w-32 relative">
            <div
              v-if="featureStore.processing"
              class="absolute z-50 top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
              <div class="loader2"></div>
            </div>
            <img
              :src="result.url"
              :alt="result.filename"
              :title="result.url"
              class="mx-auto"
              :class="`${bigResult ? 'max-h-96' : 'max-h-16'}`"
            />
          </td>

          <td v-if="showFilename" class="max-w-[320px] break-words">
            <div class="text-sm">{{ result.filename }}</div>
          </td>

          <td>
            <div class="flex flex-col space-y-2">
              <div v-if="showExtension" class="flex items-center justify-center">
                <span class="badge badge--lg">{{ result.old_extension }}</span>
                <span class="text-sm mx-1">
                  <i class="fa-solid fa-arrow-right"></i>
                </span>
                <span class="badge badge--lg badge--green">{{ result.new_extension }}</span>
              </div>

              <div v-if="showDimension" class="flex items-center justify-center">
                <div class="badge badge--lg">
                  <span>{{ result.old_width }}</span>
                  <span class="mx-1">x</span>
                  <span>{{ result.old_height }}</span>
                </div>
                <span class="text-sm mx-1">
                  <i class="fa-solid fa-arrow-right"></i>
                </span>
                <div class="badge badge--lg badge--green">
                  <span>{{ result.new_width }}</span>
                  <span class="mx-1">x</span>
                  <span>{{ result.new_height }}</span>
                </div>
              </div>

              <div v-if="showSize" class="flex items-center justify-center">
                <div>{{ formatBytes(result.old_filesize) }}</div>
                <span class="text-sm mx-1">
                  <i class="fa-solid fa-arrow-right"></i>
                </span>
                <div class="font-semibold text-green-600">{{ formatBytes(result.new_filesize) }}</div>
              </div>

              <div v-if="showReduced" class="flex space-x-2 items-center justify-center">
                <span class="mr-1">Reduced</span>
                <AppCircleProgress :size="80" :percent="result.reduced_rate"/>
              </div>
            </div>
          </td>

          <td>
            <AppCopyResultImage :url="result.url" direction="col" />
            <div class="btn btn--sm btn--secondary w-full mt-2" @click="downloadImage(result.url)">
              <i class="fa-solid fa-file-arrow-down"></i>
              <span>Download</span>
            </div>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>

  <div class="max-w-5xl mt-8 bg-white mx-auto p-4 shadow rounded flex flex-col space-y-4">
    <div class="font-semibold">Continue to:</div>
    <FeatureList
      :result-task-id="featureStore.resultTaskId"
      :features="featureStore.features.filter(i => i.id !== featureStore.feature.id)"
    />
  </div>
</template>

<script setup lang="ts">
import { useFeatureStore } from '$/stores/feature'
import { formatBytes, downloadImage, getImageURL } from '$/utils'
import AppCopyResultImage from '&/AppCopyResultImage.vue'
import AppCircleProgress from "&/AppCircleProgress.vue";
import FeatureList from "&/FeatureList.vue";

withDefaults(
  defineProps<{
    bigResult?: boolean
    showFilename?: boolean
    showOrigin?: boolean
    showExtension?: boolean
    showSize?: boolean
    showDimension?: boolean
    showReduced?: boolean
  }>(),
  {
    bigResult: false,
    showFilename: false,
    showOrigin: false,
    showExtension: false,
    showSize: false,
    showDimension: false,
    showReduced: false
  }
)

const featureStore = useFeatureStore()
</script>
