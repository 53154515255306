<template>
  <div v-if="ready" class="feature">
    <FeatureMeta :feature="featureStore.feature" />

    <div v-if="featureStore.images.length === 0">
      <FeatureInitSection :feature="featureStore.feature" />
      <PageConvertImage />
    </div>

    <div v-else class="h-full">
      <LayoutDefault @finished="onFinished">
        <template #aside>
          <div class="flex flex-col space-y-6 mt-8">
            <div class="flex space-x-8">
              <label for="convertTo" class="input-label">Convert to</label>
              <div class="flex flex-col space-y-2">
                <div v-for="format in ['PNG', 'JPG', 'WEBP', 'ICO']" :key="format">
                  <input
                    :id="format"
                    v-model="featureStore.options[slug].convertTo"
                    class="radio"
                    :value="format"
                    type="radio"
                  />
                  <label class="input-label" :for="format">{{ format }}</label>
                </div>
              </div>
            </div>
          </div>
        </template>
      </LayoutDefault>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useFeatureSetup } from '$/composables/useFeatureSetup'
import PageConvertImage from '&/landing_pages/PageConvertImage.vue'
import FeatureMeta from '&/FeatureMeta.vue'

const { ready, router, featureStore, slug, LayoutDefault, FeatureInitSection } = useFeatureSetup()

function onFinished() {
  router.push({ name: 'ConvertImageResult' })
}
</script>
