import { defineStore } from "pinia"
import apiCall from "$/plugins/axios";

export const useTaskStore = defineStore('task', {
    state: () => {
        return {
            tasks: [],
        }
    },

    actions: {
        fetchTasks() {
            return new Promise((resolve, reject) => {
                apiCall.get('/tasks')
                    .then(res => {
                        this.tasks = res.data
                        resolve(res)
                    })
                    .catch(err => {
                        reject(err)
                    })
            })
        },

        fetchTask(id) {
            return new Promise((resolve, reject) => {
                apiCall.get(`/tasks/${id}`)
                    .then(res => {
                        resolve(res)
                    })
                    .catch(err => {
                        reject(err)
                    })
            })
        },
    }
})
