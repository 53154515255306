<template>
</template>

<script setup lang="ts">
import { useHead } from '@unhead/vue'
import { useSchemaOrg, defineWebSite, defineWebPage } from "@unhead/schema-org";

const props = defineProps<{
  feature: object
}>()

useHead({
  title: props.feature.meta_title,
  meta: [
    {
      name: 'description',
      content: props.feature.meta_description,
    },
  ],
  link: [
    {
      rel: 'canonical',
      href: `/${props.feature.slug}`,
    },
  ],
})

useSchemaOrg([
  // @todo Select Identity: http://unhead.unjs.io/schema-org/recipes/identity
  defineWebSite({
    name: 'Free Online Photo Editor - Remove Background, Crop, Resize, Compress',
  }),
  defineWebPage(),
])
</script>
