<template>
  <article class="feature-page">
    <div class="max-w-4xl mx-auto px-4 py-10">
      <h2 class="text-2xl lg:text-3xl font-bold text-center mb-4">
        piceditor.io Trim Transparent Pixels: Clean Up Your Images Effortlessly
      </h2>
      <p class="text-xl text-center mb-6">Automatically Remove Unnecessary Transparent Borders</p>
      <p class="mb-4">
        Keep your PNG images clean and focused with our Trim Transparent Pixels tool. This feature automatically crops away excess transparent areas, leaving only the essential parts of your image. Perfect for streamlining designs, optimizing graphics, and maintaining a polished look.
      </p>

      <h3 class="text-2xl font-bold mt-12 mb-6">Why Use Trim Transparent Pixels?</h3>
      <div class="grid grid-cols-1 md:grid-cols-3 gap-8 feature-page__specific">
        <div class="flex flex-col space-y-4 items-center text-center">
          <img src="@/images/landing_pages/clean.png" alt="clean design" />
          <strong>Clean Design</strong>
          <p>Automatically remove unwanted transparent borders to achieve a more polished and professional image.</p>
        </div>

        <div class="flex flex-col space-y-4 items-center text-center">
          <img src="@/images/landing_pages/efficiency.png" alt="efficiency" />
          <strong>Save Time</strong>
          <p>Trim transparent pixels in just seconds, saving you time for other creative tasks.</p>
        </div>

        <div class="flex flex-col space-y-4 items-center text-center">
          <img src="@/images/landing_pages/precision.png" alt="precision" />
          <strong>Perfect Precision</strong>
          <p>Ensure your images are perfectly trimmed without losing any essential details or quality.</p>
        </div>
      </div>

      <h3 class="text-2xl font-bold mt-12 mb-6">How It Works</h3>
      <ol class="list-decimal pl-5 mb-6">
        <li><strong>Upload Your Image:</strong> Drag and drop your PNG images or upload from your device.</li>
        <li><strong>Trim Automatically:</strong> Our tool detects and removes unnecessary transparent borders instantly.</li>
        <li>
          <strong>Preview & Confirm:</strong> Review the trimmed result and ensure it meets your expectations.
        </li>
        <li><strong>Download:</strong> Save your perfectly trimmed images, ready for use.</li>
      </ol>

      <p class="">
        Designed to work seamlessly on desktops, tablets, and mobile phones, our Trim Transparent Pixels tool ensures your images are always clean and optimized, no matter where you are or what device you're using.
      </p>
    </div>
  </article>
</template>
