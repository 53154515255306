import {getCurrentFeatureSlug} from "$/utils/index.js";
import { useFeatureStore } from "$/stores/feature.js";
import { useNotify } from "$/composables/useNotify";

export function useSubmit() {
    const featureStore = useFeatureStore()
    const { pushError } = useNotify()

    const onSubmit = (onFinished) => {
        const asyncTask = [
            'upscale-restore-image',
            'colorize-black-and-white-photo',
            'remove-background',
            'blur-background',
            'trim-transparent',
            'image-shadow-generator',
        ].includes(getCurrentFeatureSlug())
        const bulkTask = featureStore.feature.bulk_request_required && featureStore.images.length > 1
        const taskOptions = { asyncTask, bulkTask }

        featureStore.processing = true
        if (!asyncTask && !bulkTask) featureStore.progressModal.show()
        featureStore
            .execute(getCurrentFeatureSlug(), taskOptions)
            .then(() => {
                if (!asyncTask && !bulkTask) onFinished()
            })
            .catch((err) => {
                console.error(err)
                pushError(err?.response?.data?.error)
                featureStore.processing = false
            })
            .finally(() => featureStore.progressModal.hide())
    }

    return { onSubmit }
}
