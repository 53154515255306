<template>
  <tr>
    <td>{{ formatTimestamp(task.started_at) }}</td>
    <td>
      <div class="flex space-x-1 items-center">
        <FeatureIcon :feature="feature" class="w-8"/>
        <div class="font-base">{{ feature.name }}</div>
      </div>
    </td>
    <td class="text-right">{{ task.output_urls.length }}</td>
    <td>
      <div :class="statusClass" class="font-bold text-sm flex items-center space-x-2">
        <span>{{ task.status.toUpperCase() }}</span>
        <AppSpinner v-if="['started'].includes(task.status)"/>
      </div>
    </td>
    <td>
      <div
        v-if="['success'].includes(task.status)"
        @click="task.output_urls.length > 1 ? downloadImages(task.output_urls) : downloadImage(task.output_urls[0])"
        class="btn btn--sm btn--secondary"
      >
        <i class="fa-solid fa-download"></i>
        <span>Download</span>
      </div>
    </td>
  </tr>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { useFeatureStore } from '$/stores/feature'
import FeatureIcon from "&/FeatureIcon.vue";
import { formatTimestamp, downloadImage, downloadImages } from '$/utils'
import AppSpinner from "&/AppSpinner.vue";

const props = defineProps<{
  task: object
}>()

const featureStore = useFeatureStore()
const feature = computed(() => featureStore.features.find(i => i.id === props.task.feature_id))
const statusClass = computed(() => {
  switch(props.task.status) {
    case 'deleted':
      return 'text-amber-600'
    case 'failure':
      return 'text-rose-600'
    case 'success':
      return 'text-green-600'
    default:
      return 'text-black'
  }
})
</script>
