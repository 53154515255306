import { defineStore } from "pinia";
import apiCall from "$/plugins/axios";

export const useFeatureStore = defineStore('feature', {
    state: () => ({
        features: [],
        feature: null,
        featureLimits: [],
        featureLimit: null,
        images: [],
        results: [],
        currentTaskId: null,
        resultTaskId: null,
        progressModal: null,
        processing: false,
        options: {
            'upscale-restore-image': {
                version: '1.4',
                upscale: 2,
            },

            'colorize-black-and-white-photo': {},

            'remove-background': {
                highQualityMode: false,
            },

            'detect-face': {
                detectionLevel: 'high'
            },

            'blur-background': {},

            'blur-face': {},

            'trim-transparent': {},

            'image-shadow-generator': {
                sigma: 20.0,
                opacity: 80,
                color: '#000000'
            },

            'crop-image': {},

            'compress-image': {
                convertToWebp: false,
                resizeWidth: null
            },

            'resize-image': {},

            'photo-to-anime-converter': {
                model: 'style1'
            },

            'convert-image': {
                convertTo: 'PNG'
            },

            'rotate-image': {
                rotateAngle: 0
            },
        },
    }),

    actions: {
        fetchFeatureLimits() {
            return new Promise((resolve, reject) => {
                apiCall.get('/features/limit')
                    .then(res => {
                        this.featureLimits = res.data
                        resolve(res)
                    })
                    .catch(err => {
                        reject(err)
                    })
            })
        },
        fetchFeatures() {
            return new Promise((resolve, reject) => {
                apiCall.get('/features')
                    .then(res => {
                        this.features = res.data
                        resolve(res)
                    })
                    .catch(err => {
                        reject(err)
                    })
            })
        },
        fetchFeature(slug) {
            return new Promise((resolve, reject) => {
                apiCall.get(`/features/${slug}`)
                    .then(res => {
                        this.feature = res.data
                        resolve(res)
                    })
                    .catch(err => {
                        reject(err)
                    })
            })
        },
        execute(slug, taskOptions) {
            return new Promise((resolve, reject) => {
                this.results = []
                const formData = new FormData();
                Object.values(this.images).forEach(v => formData.append('images[]', v))
                // Ensure each nested JSON object is stringified
                Object.keys(this.options[slug]).forEach(k => {
                    if (typeof this.options[slug][k] === 'object') { // Check if it's an object
                        formData.append(`options[${k}]`, JSON.stringify(this.options[slug][k]));
                    } else {
                        formData.append(`options[${k}]`, this.options[slug][k]);
                    }
                })
                Object.keys(taskOptions).forEach(k => {
                    formData.append(`taskOptions[${k}]`, JSON.stringify(taskOptions[k]))
                })
                apiCall.post(`/features/${slug}`, formData)
                    .then(res => {
                        if (taskOptions.asyncTask === true || taskOptions.bulkTask) {
                            if (res.data?.status === 'error') {
                                reject()
                            } else {
                                this.currentTaskId = res.data.current_task_id
                                console.log(`Current task ID: ${this.currentTaskId}`)
                                resolve()
                            }
                        } else {
                            this.results = res.data.results
                            this.resultTaskId = res.data.save_folder
                            resolve(res)
                        }
                    })
                    .catch(err => {
                        reject(err)
                    })
                    .finally(() => {
                        if (!taskOptions.asyncTask === true && !taskOptions.bulkTask) this.processing = false
                    })
            })
        }
    },

    getters: {
        popularFeatures: (state) => state.features.filter(i => i.popular)
    },
})
