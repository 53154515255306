<template>
  <CircleProgress
    :percent="Math.min(99, currentPercent)"
    :size="size"
    show-percent
    is-gradient
    :gradient="{
      angle: 90,
      startColor: '#22c55e',
      stopColor: '#0891b2'
    }"
  />
</template>

<script setup lang="ts">
import { ref, onMounted, onBeforeUnmount } from 'vue'
import CircleProgress from 'vue3-circle-progress'
import 'vue3-circle-progress/dist/circle-progress.css'

// Truyền props nếu muốn tuỳ biến
const props = withDefaults(
  defineProps<{
    size?: number
    percent?: number
    duration?: number; // số giây
    stages?: number;   // số phân đoạn
  }>(),
  {
    size: 120,
    duration: 5,
    stages: 50
  }
)

// State hiển thị
const currentPercent = ref(props.percent || 0)

// Lưu mảng các stage
const stagesRef = ref<{ time: number; percent: number }[]>([])
// Chỉ số stage hiện tại
let indexRef = 0
// Biến lưu timer để còn clearTimeout khi cần
let timer: ReturnType<typeof setTimeout> | null = null

/** Hàm chia 1 số nguyên n thành k đoạn ngẫu nhiên, tổng vẫn là n */
function randomPartition(n: number, k: number) {
  const boundaries: number[] = []
  for (let i = 0; i < k - 1; i++) {
    boundaries.push(Math.floor(Math.random() * (n - 1)) + 1)
  }
  boundaries.sort((a, b) => a - b)

  const segments: number[] = []
  let start = 0
  for (let i = 0; i < k - 1; i++) {
    segments.push(boundaries[i] - start)
    start = boundaries[i]
  }
  segments.push(n - start)

  return segments
}

/** Hàm tạo mảng stages, mỗi stage gồm {time, percent} */
function generateRandomStages(totalTime: number, totalStages: number) {
  // Tính thời gian cho 1/10 đầu tiên
  const initialTime = Math.floor(totalTime * 1000 / 10)
  const remainingTime = totalTime * 1000 - initialTime

  const timeSegments = randomPartition(remainingTime, totalStages - 1)
  const percentSegments = randomPartition(100, totalStages - 1)

  const stages: { time: number; percent: number }[] = []
  let accumulatedPercent = 0

  // Thêm stage đầu tiên với 0% trong initialTime
  stages.push({
    time: initialTime,
    percent: 0
  })

  // Tạo các stage còn lại
  for (let i = 0; i < totalStages - 1; i++) {
    accumulatedPercent += percentSegments[i]
    if (accumulatedPercent > 100) accumulatedPercent = 100
    stages.push({
      time: timeSegments[i],
      percent: accumulatedPercent
    })
  }

  return stages
}

/** Khởi tạo stages 1 lần duy nhất */
function initStages() {
  stagesRef.value = generateRandomStages(props.duration!, props.stages!)
  indexRef = 0
}

/** Hàm chạy stage tiếp theo */
function nextStage() {
  if (indexRef < stagesRef.value.length) {
    // Ép cứng <= 100 cho chắc
    currentPercent.value = stagesRef.value[indexRef].percent
    // Đặt timer cho stage tiếp
    timer = setTimeout(nextStage, stagesRef.value[indexRef].time)
    indexRef++
  }
}

/** Hàm startRandom chạy 1 lần khi component mount */
function startRandomStages() {
  currentPercent.value = 0
  // Sinh mảng stage
  initStages()
  // Bắt đầu chain timer
  nextStage()
}

onMounted(() => {
  if (!props.percent) {
    // Chỉ gọi 1 lần ở đây
    startRandomStages()
  }
})

onBeforeUnmount(() => {
  // Nếu còn timer, clear để tránh chạy tiếp khi unmount
  if (timer) clearTimeout(timer)
})
</script>

<style scoped lang="scss">
:deep(.current-counter) {
  &::after {
    content: "%";
  }
}
</style>
