<template>
  <div class="mt-20 px-4 flex flex-col items-center w-full max-w-3xl mx-auto">
    <div class="flex space-x-2 items-center">
      <FeatureIcon :feature="feature" class="w-16" />
      <h1 class="gradient-title">{{ feature.name }}</h1>
    </div>
    <h2 class="text-lg lg:text-xl mt-4 text-center">{{ feature.description }}</h2>

    <div class="mt-16 flex flex-col space-y-8 items-center lg:flex-row lg:space-x-8 lg:space-y-0">
      <div class="w-full md:w-1/2">
        <img
          v-if="introCount > 0"
          :src="`/img/landing_pages/${feature.slug}/intro${Math.floor(Math.random()*introCount)}.png`"
          :alt="feature.name"
          class="w-full shadow-xl rounded"
        />
      </div>
      <AppImageUploader class="" />
    </div>

    <div v-if="sampleCount > 0" class="mt-12">
      <div class="flex flex-wrap items-center">
        <div class="mr-8 mb-4 lg:mb-0">No image? Try one of these:</div>
        <div class="flex space-x-4">
          <div v-for="i in sampleCount" :key="i" class="transparent-wrap">
            <img
              :src="`/img/landing_pages/${feature.slug}/sample${i}.png`"
              :alt="feature.name"
              class="rounded-md shadow-md w-16 cursor-pointer hover:opacity-80"
              @click="trySample(i)"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useRoute } from "vue-router";
import { useFeatureStore } from '$/stores/feature'
import { useTaskStore } from '$/stores/task'
import FeatureIcon from '&/FeatureIcon.vue'
import AppImageUploader from '&/AppImageUploader.vue'
import {onMounted} from "vue";

const props = withDefaults(
  defineProps<{
    feature: object
    introCount?: number
    sampleCount?: number
  }>(),
  {
    multiple: true,
    introCount: 0,
    sampleCount: 0,
  }
)

const route = useRoute()
const featureStore = useFeatureStore()
const taskStore = useTaskStore()

onMounted(() => {
  if (route.query.task) loadImagesFromTask(route.query.task)
  else if (route.query.image_url) loadImageFromUrl(route.query.image_url)
})

function trySample(i) {
  loadImageFromUrl(`/img/landing_pages/${props.feature.slug}/sample${i}.png`)
}

function loadImageFromUrl(url) {
  fetch(url)
    .then((response) => response.blob())
    .then((blob) => {
      // Tách lấy phần extension từ content-type
      let extension = blob.type.split('/')[1]

      // Nếu không thuộc dạng image hoặc không nằm trong danh sách cho phép,
      // gán đại extension là 'png'
      const validImageExtensions = ['png', 'jpeg', 'jpg', 'gif', 'webp', 'svg+xml']
      if (!blob.type.startsWith('image/') || !validImageExtensions.includes(extension)) {
        extension = 'png'
      }

      // Tạo tên file unique để tránh trùng lặp
      const uniqueId = Date.now() + '-' + Math.floor(Math.random() * 1000)

      // Tạo đối tượng File
      const file = new File([blob], `image-${uniqueId}.${extension}`, { type: blob.type })

      // Thêm file vào store
      featureStore.images.push(file)
    })
    .catch((error) => {
      console.error('Error loading image:', error)
    })
}

function loadImagesFromTask(id) {
  taskStore.fetchTask(id)
    .then(res => {
      if (res.data) {
        res.data.output_urls.forEach((url) => loadImageFromUrl(url))
      }
    })
}
</script>
