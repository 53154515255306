<template>
  <div v-if="store.ready" class="">
    <TheHeader/>

    <div class="profile h-full">
      <button data-drawer-target="default-sidebar" data-drawer-toggle="default-sidebar" aria-controls="default-sidebar" type="button" class="inline-flex items-center fixed top-20 left-4 text-sm text-gray-500 rounded-lg sm:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600 z-30">
        <span class="sr-only">Open sidebar</span>
        <svg class="w-10 h-10" aria-hidden="false" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
          <path clip-rule="evenodd" fill-rule="evenodd" d="M2 4.75A.75.75 0 012.75 4h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 4.75zm0 10.5a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5a.75.75 0 01-.75-.75zM2 10a.75.75 0 01.75-.75h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 10z"></path>
        </svg>
      </button>

      <MyAccountAside/>

      <main class="p-4 pt-20 sm:ml-64 bg-zinc-100 h-full">
        <router-view/>
      </main>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useAuth } from '$/composables/useAuth'
import TheHeader from "&/TheHeader.vue";
import {onMounted} from "vue";
import MyAccountAside from "&/auth/MyAccountAside.vue";

const { store, sessionStore, featureStore } = useAuth()

const access_token = localStorage.getItem('access-token')

onMounted(async () => {
  await store.fetchSystemConfigs()
  await featureStore.fetchFeatures()

  if (access_token) {
    sessionStore.signInWithToken().finally(() => {
      store.ready = true
    })
  } else {
    store.ready = true
  }
})
</script>
