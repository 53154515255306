<template>
  <div class="px-4 mx-auto max-w-4xl">
    <div class="flex flex-col items-center">
      <h1 class="gradient-title">{{ title }}</h1>
      <h2 id="resultTop" class="mt-4 text-lg lg:text-2xl text-center">
        Preview your images below and download with just a click
      </h2>

      <AuthModalTrigger class="mt-8">
        Sign up for a free account to save task history and easily re-download your results whenever you need!
      </AuthModalTrigger>

      <div
        class="w-full max-w-3xl mt-8 flex flex-col space-y-4 md:flex-row md:space-y-0 md:space-x-8 justify-center items-center"
      >
        <button class="text-xl text-primary" @click="back">
          <i class="fa-regular fa-circle-left"></i>
          <span>Back</span>
        </button>

        <button v-if="downloadableResults.length > 0" class="btn btn--primary btn--lg" :disabled="store.sending" @click="download">
          <i class="fa-solid fa-download"></i>
          <span v-if="featureStore.results.length > 1">Download all {{ downloadableResults.length }} images</span>
          <span v-else>Download result image</span>
        </button>

        <div></div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from '$/stores'
import { useFeatureStore } from '$/stores/feature'
import { useFlowbite } from '$/composables/useFlowbite'
import { downloadImage, downloadImages } from '$/utils'
import AuthModalTrigger from "&/auth/AuthModalTrigger.vue";

const props = defineProps<{
  title: string
  slug: string
}>()

const router = useRouter()
const store = useStore()
const featureStore = useFeatureStore()

useFlowbite(['modals'])

const downloadableResults = computed(() => featureStore.results.filter(i => i.url ))

function back() {
  router.push({ path: `/${props.slug}`, query: { keep_uploaded: true } })
}

function download() {
  store.setSending(true)
  if (downloadableResults.value.length > 1) {
    const imageUrls = downloadableResults.value.map((result) => result.url)
    downloadImages(imageUrls).finally(() => store.setSending(false))
  } else {
    downloadImage(downloadableResults.value[0].url).finally(() => store.setSending(false))
  }
}
</script>
