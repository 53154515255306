<template>
  <div class="feature-result">
    <FeatureResultSection :slug="featureStore.feature.slug" title="Your images have been removed background!" />
    <ResultDefault
      v-if="featureStore.options[featureStore.feature.slug].trimTransparent"
      big-result
    />
    <ResultSlider v-else />
  </div>

  <ModalFeatureProgress />
</template>

<script setup lang="ts">
import { useFeatureStore } from '$/stores/feature'
import FeatureResultSection from '&/FeatureResultSection.vue'
import ResultSlider from '&/ResultSlider.vue'
import ResultDefault from "&/ResultDefault.vue";
import ModalFeatureProgress from "&/ModalFeatureProgress.vue";

const featureStore = useFeatureStore()
</script>
