import { ref, watch, computed } from 'vue'
import { useFeatureStore } from '$/stores/feature'

// Helper for async dimension checking
function checkExceedFileDimension(file, maxWidth, maxHeight) {
    return new Promise((resolve) => {
        const img = new Image()
        const url = URL.createObjectURL(file)

        img.onload = () => {
            const exceed = img.width > maxWidth || img.height > maxHeight
            URL.revokeObjectURL(url)
            resolve(exceed)
        }
        img.src = url
    })
}

export function useValidate() {
    const featureStore = useFeatureStore()

    // Invalid File Count
    const invalidFileCount = ref(false)

    function checkFileCount() {
        invalidFileCount.value = false

        const maxFileCount = featureStore.featureLimit.max_file_count
        const exceed = featureStore.images.length > maxFileCount
        if (exceed) {
            console.warn(`Invalid file count: ${featureStore.images.length} / ${maxFileCount}`)
            invalidFileCount.value = true
        }
    }

    // Invalid File Size
    const invalidFileSize = ref(false)
    const invalidFileSizeIndexes = ref([])

    function checkFileSize() {
        invalidFileSize.value = false
        invalidFileSizeIndexes.value = []

        const maxFileSize = featureStore.featureLimit.max_file_size * 1024 * 1024
        featureStore.images.map((file, index) => {
            if (file.size > maxFileSize) {
                invalidFileSize.value = true
                invalidFileSizeIndexes.value.push(index)
                console.warn(`Invalid file size: ${file.size} / ${maxFileSize}`)
            }
        })
    }

    // Invalid File Dimension
    // We need an async check for each image's dimension, so we use a ref
    // and a watcher instead of doing it purely in a computed.
    const invalidFileDimension = ref(false)
    const invalidFileDimensionIndexes = ref([])

    async function checkFileDimension() {
        invalidFileDimension.value = false
        invalidFileDimensionIndexes.value = []

        const { max_width: maxWidth, max_height: maxHeight } = featureStore.featureLimit

        let index = 0
        for (const file of featureStore.images) {
            const exceed = await checkExceedFileDimension(file, maxWidth, maxHeight)
            if (exceed) {
                invalidFileDimension.value = true
                invalidFileDimensionIndexes.value.push(index)
                console.warn(`Invalid file dimension: Max ${maxWidth}x${maxHeight}`)
            }
            index++
        }
    }

    // Whenever the user’s file list changes, re-check dimensions
    watch(
        () => featureStore.images,
        () => {
            if (featureStore.images.length > 0) {
                checkFileCount()
                checkFileSize()
                checkFileDimension()
            }
        },
        { immediate: true, deep: true }
    )

    // Combined Invalid
    const invalid = computed(() => {
        return invalidFileCount.value || invalidFileSize.value || invalidFileDimension.value
    })

    return {
        invalid,
        invalidFileCount,
        invalidFileSizeIndexes,
        invalidFileDimensionIndexes,
    }
}
