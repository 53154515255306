<template>
  <notifications
    position="bottom right"
    class="w-[80%] md:w-[500px]"
  >
    <template #body="props">
      <div :class="`relative notification notification--${props.item.type}`">
        <p class="title">
          {{ props.item.title }}
        </p>
        <button class="absolute top-4 right-4 text-xl" @click="props.close">
          <i class="fa-regular fa-circle-xmark"></i>
        </button>
        <div v-html="props.item.text?.replace(/\n/g, '<br>')" />
      </div>
    </template>
  </notifications>

  <router-view/>
</template>
