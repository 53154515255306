<template>
  <div class="px-2 py-12 sm:px-8 mx-auto max-w-screen-xl">
    <h1 class="page-title">Recent tasks</h1>

    <div class="mt-6 max-w-screen-2xl w-full">
      <AppNotice class="my-8">Your files will be deleted after 1 day. Please download them if needed.</AppNotice>
      <div class=" overflow-scroll">
        <table class="shadow-lg table table--bordered bg-white min-w-[800px]">
          <thead>
          <tr>
            <th>Started at</th>
            <th>Tool name</th>
            <th>File count</th>
            <th>Status</th>
            <th>Download</th>
          </tr>
          </thead>
          <tbody>
          <MyTaskItem v-for="task in taskStore.tasks" :task="task"/>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { onMounted } from "vue";
import { useTaskStore } from '$/stores/task'
import MyTaskItem from "&/auth/MyTaskItem.vue";
import AppNotice from "&/AppNotice.vue";

const taskStore = useTaskStore()

onMounted(() => {
  taskStore.fetchTasks()
})
</script>
