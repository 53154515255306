<template>
  <div v-if="ready" class="feature">
    <FeatureMeta :feature="featureStore.feature" />

    <div v-if="featureStore.images.length === 0">
      <FeatureInitSection :feature="featureStore.feature"
      />
      <AppNotice class="mx-auto mt-8 max-w-[90%] md:max-w-[500px]"
        >Experience Real-Time Editing: Watch your shadow effects come to life instantly as you adjust
        settings.</AppNotice
      >
      <PageGenerateShadow />
    </div>

    <div v-else class="h-full">
      <LayoutDefault @finished="onFinished">
        <div class="mt-4 mx-auto px-8">
          <div class="mx-auto max-w-2xl">
            <img
              id="img"
              :src="getImageURL(featureStore.images[0])"
              :alt="featureStore.images[0].name"
              class="mx-auto"
              :style="`filter: drop-shadow(${info.displayX}px ${info.displayY}px ${info.displaySigma}px ${hex2rbga(featureStore.options[slug].color, featureStore.options[slug].opacity / 100)}`"
              @load="handImageLoad"
            />
          </div>
        </div>

        <template #aside>
          <form>
            <fieldset :disabled="featureStore.processing" class="flex flex-col space-y-6 mt-8">
              <div class="flex flex-col space-y-2">
                <label for="xOffset" class="input-label">X offset (pixel)</label>
                <div class="flex space-x-2 items-center">
                  <input
                    id="xOffset"
                    v-model="featureStore.options[slug].xOffset"
                    type="range"
                    :min="-info.maxX"
                    :max="info.maxX"
                    class="flex-grow"
                  />
                  <input v-model="featureStore.options[slug].xOffset" type="number" class="input-field text-right w-24" />
                </div>
              </div>

              <div class="flex flex-col space-y-2">
                <label for="yOffset" class="input-label">Y offset (pixel)</label>
                <div class="flex space-x-2 items-center">
                  <input
                    id="yOffset"
                    v-model="featureStore.options[slug].yOffset"
                    type="range"
                    :min="-info.maxY"
                    :max="info.maxY"
                    class="flex-grow"
                  />
                  <input
                    v-model="featureStore.options[slug].yOffset"
                    type="number"
                    min="-100"
                    max="100"
                    class="input-field text-right w-24"
                  />
                </div>
              </div>

              <div class="flex flex-col space-y-2">
                <label for="sigma" class="input-label">Blur (pixel)</label>
                <div class="flex space-x-2 items-center">
                  <input
                    id="sigma"
                    v-model="featureStore.options[slug].sigma"
                    type="range"
                    min="0"
                    max="100"
                    class="flex-grow"
                  />
                  <input
                    v-model="featureStore.options[slug].sigma"
                    type="number"
                    min="0"
                    max="100"
                    class="input-field text-right w-24"
                  />
                </div>
              </div>

              <div class="flex flex-col space-y-2">
                <label for="opacity" class="input-label">Opacity</label>
                <div class="flex space-x-2 items-center">
                  <input
                    id="opacity"
                    v-model="featureStore.options[slug].opacity"
                    type="range"
                    min="0"
                    max="100"
                    step="10"
                    class="flex-grow"
                  />
                  <input
                    v-model="featureStore.options[slug].opacity"
                    type="number"
                    min="0"
                    max="100"
                    step="10"
                    class="input-field text-right w-24"
                  />
                </div>
              </div>

              <div class="flex flex-col space-y-2">
                <label for="color" class="input-label">Color</label>
                <div class="flex space-x-2 items-center">
                  <input id="color" v-model="featureStore.options[slug].color" type="color" class="flex-grow" />
                  <input v-model="featureStore.options[slug].color" type="text" class="input-field w-24 text-right" />
                </div>
              </div>
            </fieldset>
          </form>

          <AppImageRatioInfo
            v-if="info.ratio"
            :real-width="imageDimension.width"
            :real-height="imageDimension.height"
            :client-width="info.img.clientWidth"
            :client-height="info.img.clientHeight"
            :ratio="info.ratio"
            class="mt-8"
          />
        </template>
      </LayoutDefault>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useFeatureSetup } from '$/composables/useFeatureSetup'
import PageGenerateShadow from '&/landing_pages/PageGenerateShadow.vue'
import FeatureMeta from '&/FeatureMeta.vue'
import { getCurrentFeatureSlug, getImageURL, hex2rbga } from '$/utils'
import { onMounted, ref, watch } from 'vue'
import AppImageRatioInfo from '&/AppImageRatioInfo.vue'

const { ready, route, router, featureStore, LayoutDefault, FeatureInitSection, AppNotice } = useFeatureSetup()
const imageDimension = ref({})
const info = ref({})
const slug = getCurrentFeatureSlug()

function handImageLoad(event) {
  imageDimension.value.width = event.target.naturalWidth
  imageDimension.value.height = event.target.naturalHeight
}

function onFinished() {
  router.push({ name: 'GenerateShadowResult' })
}

onMounted(() => {
  if (route.query.keep_uploaded && featureStore.images[0]) {
    init()
  }
})

watch(
  () => featureStore.images,
  (newImages) => {
    if (newImages[0]) {
      init()
    }
  },
  { deep: true }
)

watch(
  () => featureStore.options[slug],
  () => {
    info.value.displayX = featureStore.options[slug].xOffset / info.value.ratio
    info.value.displayY = featureStore.options[slug].yOffset / info.value.ratio
    info.value.displaySigma = featureStore.options[slug].sigma / info.value.ratio
  },
  { deep: true }
)

function init() {
  setTimeout(() => {
    info.value.img = document.getElementById('img')
    featureStore.options[slug].xOffset = Math.round(imageDimension.value.width / 100)
    featureStore.options[slug].yOffset = Math.round(imageDimension.value.height / 100)
    info.value.ratio = (imageDimension.value.width / info.value.img.clientWidth).toFixed(2).replace(/\.00$/, '')
    info.value.maxX = Math.round(imageDimension.value.width / 5)
    info.value.maxY = Math.round(imageDimension.value.height / 5)
  }, 1500)
}
</script>
