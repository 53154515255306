<template>
  <div class="overflow-x-scroll mt-6 p-2 flex flex-col space-y-8">
    <div v-for="(_, index) in featureStore.images" :key="index" class="flex flex-col justify-center">
      <div v-if="!hasError(index)" class="flex space-x-4 justify-center mb-4 mx-auto max-w-2xl">
        <div class="btn btn--sm btn--secondary" @click="showSlider = !showSlider">
          <i class="fa-solid fa-toggle-off"></i>
          <span>Change view</span>
        </div>

        <AppCopyResultImage :url="featureStore.results[index].url" />
      </div>

      <div class="relative mx-auto">
        <div v-if="hasError(index)" class="max-w-[480px] mx-auto relative">
          <img :src="getImageURL(featureStore.images[index])" class="w-full">
          <div
            class="w-full absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 bg-red-800 bg-opacity-90 text-red-100 p-4">
            Sorry, we couldn't process this image. Please try again later.
          </div>
        </div>

        <div v-else>
          <img :src="getImageURL(featureStore.images[index])" class="hidden" @load="(event) => handImageLoad(event, 'first', index)"/>
          <img :src="featureStore.results[index].url" class="hidden" @load="(event) => handImageLoad(event, 'second', index)"/>
          <div class="flex flex-col space-y-8 lg:flex-row lg:space-x-8 lg:space-y-0">
            <div v-if="showSlider"
                 class="max-w-[480px] mx-auto relative"
                 :class="{'opacity-60': featureStore.processing}"
            >
              <div
                v-if="featureStore.processing"
                class="absolute z-50 top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
                <div class="loader2"></div>
              </div>
              <AppComparisonSlider :first="getImageURL(featureStore.images[index])" :second="featureStore.results[index].url" />
              <div v-if="showDimensions" class="absolute top-0 left-0 z-10">
                <span class="badge badge--xl shadow-lg">{{ firstImageDimensions[index]?.width }} x {{ firstImageDimensions[index]?.height }}</span>
              </div>
              <div v-if="showDimensions" class="absolute top-0 right-0 z-10">
                <span class="badge badge--xl shadow-lg badge--green">{{ secondImageDimensions[index]?.width }} x {{ secondImageDimensions[index]?.height }}</span>
              </div>
            </div>

            <div v-else class="flex-grow flex max-w-[960px] mx-auto relative">
              <div class="w-1/2 relative">
                <img :src="getImageURL(featureStore.images[index])" class="w-full"/>
                <div v-if="showDimensions" class="absolute top-0 left-0 z-10">
                  <span class="badge badge--xl shadow-lg">{{ firstImageDimensions[index]?.width }} x {{ firstImageDimensions[index]?.height }}</span>
                </div>
              </div>
              <div class="w-1/2 relative">
                <img :src="featureStore.results[index].url"  class="w-full" />
                <div v-if="showDimensions" class="absolute top-0 right-0 z-10">
                  <span class="badge badge--xl shadow-lg badge--green">{{ secondImageDimensions[index]?.width }} x {{ secondImageDimensions[index]?.height }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="max-w-5xl mt-8 bg-white mx-auto p-4 shadow rounded flex flex-col space-y-4">
    <div class="font-semibold">Continue to:</div>
    <FeatureList
      :result-task-id="featureStore.resultTaskId"
      :features="featureStore.features.filter(i => i.id !== featureStore.feature.id)"
    />
  </div>
</template>

<script setup lang="ts">
import { ref, computed } from 'vue'
import { useFeatureStore } from '$/stores/feature'
import AppComparisonSlider from '&/AppComparisonSlider.vue'
import AppCopyResultImage from '&/AppCopyResultImage.vue'
import { getImageURL } from '$/utils'
import FeatureList from "&/FeatureList.vue";

const featureStore = useFeatureStore()
const showSlider = ref(true)
const firstImageDimensions = ref([])
const secondImageDimensions = ref([])

const showDimensions = computed(() => ['upscale-restore-image'].includes(featureStore.feature.slug))

function handImageLoad(event, position, index) {
  if (position === 'first' && firstImageDimensions.value[index]?.width) return
  if (position === 'second' && secondImageDimensions.value[index]?.width) return

  const dimensions = {
    width: event.target.naturalWidth,
    height: event.target.naturalHeight,
  };

  if (position === 'first') {
    firstImageDimensions.value[index] = dimensions;
  } else {
    secondImageDimensions.value[index] = dimensions;
  }
}

function hasError(index) {
  return featureStore.results[index].error
}
</script>
