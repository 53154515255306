import axios from 'axios'

const apiCall = axios.create({
    baseURL: '/api/v1',
    withCredentials: false,
    timeout: 120000,
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'multipart/form-data'
    }
})

export default apiCall
