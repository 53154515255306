<template>
  <div v-if="ready" class="feature">
    <FeatureMeta :feature="featureStore.feature" />

    <div v-if="featureStore.images.length === 0">
      <FeatureInitSection
        :feature="featureStore.feature"
        :intro-count="2"
        :sample-count="4"
      />
      <PageColorizeBlackAndWhite />
    </div>

    <div v-else class="h-full">
      <LayoutDefault @finished="onFinished">
      </LayoutDefault>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useFeatureSetup } from '$/composables/useFeatureSetup'
import PageColorizeBlackAndWhite from '&/landing_pages/PageColorizeBlackAndWhite.vue'
import FeatureMeta from '&/FeatureMeta.vue'

const { ready, router, featureStore, LayoutDefault, FeatureInitSection } = useFeatureSetup()

function onFinished() {
  router.push({ name: 'ColorizeBlackAndWhiteResult' })
}
</script>
