<template>
  <div v-if="ready" class="feature">
    <FeatureMeta :feature="featureStore.feature" />

    <div v-if="featureStore.images.length === 0">
      <FeatureInitSection
        :feature="featureStore.feature"
        :intro-count="3"
        :sample-count="4"
      />
      <PageRemoveBackground />
    </div>

    <div v-else class="h-full">
      <LayoutDefault
        @finished="onFinished"
      >
        <template #aside>
          <div class="flex">
            <label
              for="highQualityMode"
              class="w-1/2 lg:w-2/3 input-label icon-option icon-option--pro"
            >
              <img src="@/images/features/remove-background/high-quality-mode.jpg" alt="High quality mode" class="mb-2"/>
              <input
                id="highQualityMode"
                v-model="featureStore.options[slug].highQualityMode"
                class="checkbox"
                type="checkbox"
              />
              High quality mode
            </label>
          </div>
        </template>
      </LayoutDefault>
    </div>
  </div>
</template>

<script setup lang="ts">
import { watch } from 'vue'
import { useFeatureSetup } from '$/composables/useFeatureSetup'
import PageRemoveBackground from '&/landing_pages/PageRemoveBackground.vue'
import FeatureMeta from '&/FeatureMeta.vue'

const { ready, router, slug, sessionStore, featureStore, LayoutDefault, FeatureInitSection } = useFeatureSetup()

function onFinished() {
  router.push({ name: 'RemoveBackgroundResult' })
}

watch(
  () => featureStore.options[slug].highQualityMode,
  (newVal, oldVal) => {
    if (newVal === true && featureStore.featureLimit.restrict_options.includes('high_quality_mode')) {
      sessionStore.authModal.show()
      setTimeout(() => {
        featureStore.options[slug].highQualityMode = oldVal
      }, 500)
    }
  }, { immediate: true }
)
</script>
