<template>
  <AuthModal/>

  <div v-if="store.ready" class="flex flex-col h-full">
    <TheHeader />
    <div class="flex-grow pt-0">
      <router-view :key="$route.fullPath" />

      <FeaturePopular v-if="showPopular" class="my-20"/>
    </div>
    <TheFooter />
  </div>
</template>

<script setup lang="ts">
import { onMounted, computed } from 'vue'
import { useRoute } from "vue-router";
import { useStore } from '$/stores'
import { useFeatureStore } from '$/stores/feature'
import { useSessionStore } from '$/stores/session'
import TheHeader from '&/TheHeader.vue'
import TheFooter from '&/TheFooter.vue'
import FeaturePopular from "&/FeaturePopular.vue";
import { useHead } from '@unhead/vue'
import AuthModal from "&/auth/AuthModal.vue";

const route = useRoute()
const store = useStore()
const featureStore = useFeatureStore()
const sessionStore = useSessionStore()
const access_token = localStorage.getItem('access-token')

const showPopular = computed(() => {
  return !['FeatureIndex', 'PageFAQ'].includes(route.name)
})

onMounted(async () => {
  await store.fetchSystemConfigs()
  await featureStore.fetchFeatureLimits()
  await featureStore.fetchFeatures()

  if (access_token) {
    sessionStore.signInWithToken().finally(() => {
      store.ready = true
    })
  } else {
    store.ready = true
  }
})

useHead({
  templateParams: {
    schemaOrg: {
      host: 'https://piceditor.io',
    }
  }
})
</script>
