<template>
  <div
    id="authModal"
    data-modal-backdrop="static"
    tabindex="-1"
    aria-hidden="true"
    class="hidden overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full"
  >
    <div class="relative p-4 w-full max-w-lg max-h-full">
      <div class="relative bg-white rounded-lg shadow dark:bg-gray-700">
        <div class="flex items-center justify-between px-4 pt-2 rounded-t">
          <button type="button" class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                  data-modal-hide="authModal"
          >
            <svg class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
              <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
            </svg>
            <span class="sr-only">Close modal</span>
          </button>
        </div>

        <div class="px-4 py-0 space-y-4">
          <div>
            <ul
              class="flex space-x-6 justify-center -mb-px font-bold text-center"
              id="auth-tab"
              data-tabs-toggle="#auth-tab-content"
              data-tabs-active-classes="text-purple-600 hover:text-purple-600 dark:text-purple-500 dark:hover:text-purple-500 border-purple-600 dark:border-purple-500"
              data-tabs-inactive-classes="dark:border-transparent text-gray-500 hover:text-gray-600 dark:text-gray-400 border-gray-100 hover:border-gray-300 dark:border-gray-700 dark:hover:text-gray-300"
              role="tablist"
            >
              <li class="" role="presentation">
                <button
                  class="inline-block p-2 border-b-2 rounded-t-lg"
                  id="sign-up-tab"
                  data-tabs-target="#sign-up"
                  type="button"
                  role="tab"
                  aria-controls="sign-up"
                  aria-selected="false">
                  Sign up
                </button>
              </li>
              <li class="" role="presentation">
                <button
                  class="inline-block p-2 border-b-2 rounded-t-lg"
                  id="sign-in-tab"
                  data-tabs-target="#sign-in"
                  type="button"
                  role="tab"
                  aria-controls="sign-in"
                  aria-selected="false">
                  Sign in
                </button>
              </li>
            </ul>
          </div>

          <div id="auth-tab-content">
            <div
              class="hidden p-4 rounded-lg bg-gray-50 dark:bg-gray-800"
              id="sign-up"
              role="tabpanel"
              aria-labelledby="sign-up-tab"
            >
              <SignUpForm simple/>
            </div>
            <div
              class="hidden p-4 rounded-lg bg-gray-50 dark:bg-gray-800"
              id="sign-in"
              role="tabpanel"
              aria-labelledby="sign-in-tab"
            >
              <SignInForm simple/>
            </div>
          </div>
        </div>
        <!-- Modal footer -->
        <div class="flex items-center p-4 md:p-5 border-t border-gray-200 rounded-b dark:border-gray-600">
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import SignUpForm from "&/auth/SignUpForm.vue";
import SignInForm from "&/auth/SignInForm.vue";
import { useSessionStore } from '$/stores/session'
import { useFlowbite } from '$/composables/useFlowbite'
import {onBeforeUnmount, onMounted} from "vue";
import {Modal} from "flowbite";

const sessionStore = useSessionStore()

useFlowbite(['tabs'])

onMounted(() => {
  createModal()
})

onBeforeUnmount(() => {
  sessionStore.authModal.hide()
})

function createModal() {
  sessionStore.authModal = null

  const $modal = document.getElementById('authModal')
  const modalOptions = {
    backdrop: 'static'
  }

  sessionStore.authModal = new Modal($modal, modalOptions)
}
</script>
