<template>
  <div>
    <input
      ref="filesInput"
      type="file"
      :multiple="multiple"
      class="hidden"
      accept="image/*"
      @change="handleFilesChange"
    />

    <div class="flex flex-col space-y-6">
      <div
        ref="uploadField"
        class="bg-white max-w-[340px] p-1 border-4 border-dashed border-sky-500 flex space-x-2 items-center shadow-lg cursor-pointer hover:bg-sky-100 transition"
        :class="{ 'cursor-not-allowed bg-gray-300': disabled }"
        @click="triggerFilesInput"
      >
        <img src="@/images/icons/add_image.png" alt="Upload images" class="w-1/3" />
        <div>
          <div class="text-sky-600 text-lg">Upload {{ imageWord }}</div>
          <div class="italic">(Select or drop here)</div>
          <div class="mt-1 text-sm">Max {{ featureStore.featureLimit.max_file_count }} and {{ featureStore.featureLimit.max_file_size }} MB per file</div>
        </div>
      </div>

      <div>
        <div class="flex space-x-2 w-full max-w-[340px]">
          <div class="font-bold">
            <span class="text-purple-600">CTRL + V</span>
            <span class="ml-2">to paste {{ imageWord }} from clipboard</span>
          </div>
        </div>

        <div class="mt-2 flex space-x-2 w-full max-w-[340px]">
          <input
            v-model="imageUrl"
            type="url"
            placeholder="Or enter image URL"
            class="input-field flex-grow"
            :disabled="disabled"
            @keydown.enter="handleUrlUpload"
          />
          <button class="btn btn--primary min-w-[120px]" :disabled="disabled || !imageUrl" @click="handleUrlUpload">
            <i class="fa-solid fa-upload"></i>
            <span>Upload</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted, onUnmounted, computed } from 'vue'
import { useFeatureStore } from '$/stores/feature'
import { useNotify } from '$/composables/useNotify'

const emit = defineEmits(['uploaded'])

const props = withDefaults(
  defineProps<{
    disabled?: boolean
  }>(),
  {
    disabled: false
  }
)

const featureStore = useFeatureStore()
const { pushError } = useNotify()
const filesInput = ref(null)
const imageUrl = ref('')
const multiple = computed(() => featureStore.featureLimit.max_file_count > 1)
const imageWord = computed(() => (multiple.value ? 'images' : 'image'))
const dragOverlay = document.getElementById('dragOverlay')

onMounted(() => {
  window.addEventListener('paste', handlePaste)
  window.addEventListener('dragover', dragOver)
  window.addEventListener('dragenter', dragEnter)
  window.addEventListener('dragleave', dragLeave)
  window.addEventListener('drop', handleDrop)
})

onUnmounted(() => {
  window.removeEventListener('paste', handlePaste)
  window.removeEventListener('dragover', dragOver)
  window.removeEventListener('dragenter', dragEnter)
  window.removeEventListener('dragleave', dragLeave)
  window.removeEventListener('drop', handleDrop)
})

function triggerFilesInput() {
  if (props.disabled) return
  filesInput.value.click()
}

function handleFilesChange(event) {
  const files = Array.from(event.target.files)
  processFiles(files)
}

function dragOver(event) {
  event.preventDefault() // Necessary to allow the drop
  if (props.disabled) return

  dragOverlay.classList.remove('hidden')
}

let dragCounter = 0

function dragEnter(event) {
  event.preventDefault()
  if (props.disabled) return

  dragCounter++
  dragOverlay.classList.remove('hidden')
}

function dragLeave(event) {
  event.preventDefault()
  if (props.disabled) return

  dragCounter--
  if (dragCounter === 0) {
    dragOverlay.classList.add('hidden')
  }
}

function handleDrop(event) {
  event.preventDefault()
  dragCounter = 0 // Reset counter
  dragOverlay.classList.add('hidden')
  if (props.disabled) return
  const files = Array.from(event.dataTransfer.files)
  processFiles(files)
}

function handlePaste(event) {
  if (props.disabled) return

  const clipboardItems = event.clipboardData?.items || []
  const files = []

  for (let i = 0; i < clipboardItems.length; i++) {
    const item = clipboardItems[i]
    if (item.type.startsWith('image/')) {
      files.push(item.getAsFile())
    }
  }

  if (files.length > 0) {
    processFiles(multiple.value ? files : [files[0]])
  }
}

function handleUrlUpload() {
  if (!imageUrl.value) return

  fetch(imageUrl.value)
    .then((response) => {
      if (!response.ok) {
        throw new Error('Invalid image URL')
      }
      return response.blob()
    })
    .then((blob) => {
      let extension = blob.type.split('/')[1]

      if (extension === 'octet-stream') {
        const urlExtensionMatch = imageUrl.value.match(/\.\w+$/)
        if (urlExtensionMatch) {
          extension = urlExtensionMatch[0].slice(1)
        } else {
          extension = 'jpg'
        }
      }

      const uniqueId = Date.now() + '-' + Math.floor(Math.random() * 1000)
      const file = new File([blob], `image-from-url-${uniqueId}.${extension}`, { type: blob.type })
      processFiles([file])
      imageUrl.value = ''
    })
    .catch((error) => {
      pushError(error.message)
    })
}

function processFiles(files) {
  const imageFiles = files.filter((file) => file.type.startsWith('image/'))

  if (imageFiles.length > 0) {
    featureStore.images.push(...imageFiles)
    filesInput.value.value = null
    emit('uploaded')
  } else {
    pushError('Only image files are allowed')
  }
}
</script>
