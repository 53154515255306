<template>
  <div v-if="ready" class="feature">
    <FeatureMeta :feature="featureStore.feature" />

    <div v-if="featureStore.images.length === 0">
      <FeatureInitSection :feature="featureStore.feature" />
      <PageResizeImage />
    </div>

    <div v-else class="h-full">
      <LayoutDefault @finished="onFinished">
        <template #aside>
          <div class="flex flex-col space-y-6 mt-8">
            <div class="flex items-center justify-between">
              <label for="resizeWidth" class="input-label">Resize width (px)</label>
              <input
                id="resizeWidth"
                v-model="featureStore.options[slug].resizeWidth"
                min="1"
                max="4096"
                type="number"
                class="input-field w-32 text-right"
                placeholder="1024"
              />
            </div>
            <div class="flex items-center justify-between">
              <label for="resizeHeight" class="input-label">Resize height (px)</label>
              <input
                id="resizeHeight"
                v-model="featureStore.options[slug].resizeHeight"
                min="1"
                max="4096"
                type="number"
                class="input-field w-32 text-right"
                placeholder="512"
              />
            </div>
          </div>
        </template>
      </LayoutDefault>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useFeatureSetup } from '$/composables/useFeatureSetup'
import PageResizeImage from '&/landing_pages/PageResizeImage.vue'
import FeatureMeta from '&/FeatureMeta.vue'

const { ready, router, featureStore, slug, LayoutDefault, FeatureInitSection } = useFeatureSetup()

function onFinished() {
  router.push({ name: 'ResizeImageResult' })
}
</script>
