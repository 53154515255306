<template>
  <div>
    <div class="flex flex-col space-y-8 items-center justify-center">
      <AppLogo v-if="!simple"/>
      <h1 class="gradient-title">Create Free Account</h1>
    </div>

    <div class="mt-12 max-w-[400px] mx-auto">
      <form class="default-form" @submit.prevent="onSignUp">
        <div>
          <AppInputField v-model="form.name" label="Name" placeholder="Name" :maxlength="30" autofocus>
            <i class="fa-solid fa-user"></i>
          </AppInputField>
        </div>

        <div>
          <AppInputField v-model="form.email" label="Email" placeholder="Email" type="email">
            <i class="fa-solid fa-envelope"></i>
          </AppInputField>
        </div>

        <div>
          <AppInputField v-model="form.password" label="Password" placeholder="Password (min 6 characters" type="password">
            <i class="fa-solid fa-key"></i>
          </AppInputField>
        </div>

        <div class="flex justify-center">
          <button :disabled="store.sending" type="submit" class="btn btn--lg btn--primary">
            <i class="fa-solid fa-user-plus"></i>
            <span>Sign up</span>
          </button>
        </div>

        <div v-if="!simple" class="flex flex-col space-y-4 mt-8">
          <div>
            <span class="mr-1">Already have an account?</span>
            <router-link
              :to="{ name: 'SignIn' }"
              class="text-primary font-bold"
            >Sign in</router-link
            >
          </div>
        </div>
      </form>

      <hr class="my-6 border border-solid border-gray-300" />
      <div class="">
        <OAuth/>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { reactive } from 'vue'
import { useAuth } from '$/composables/useAuth'

const { router, store, sessionStore, pushNotify, pushError, OAuth, AppInputField, AppLogo } = useAuth()

withDefaults(
  defineProps<{
    simple?: boolean
  }>(),
  {
    simple: false
  }
)

const form = reactive({
  name: '',
  email: '',
  password: '',
})

const onSignUp = function() {
  store.sending = true
  sessionStore
    .signUp(form)
    .then(() => {
      router.push({ name: 'SignIn' })
      let msg = `A confirmation email was sent to your account at '${form.email}'. You must follow the instructions in the email before your account can be activated`
      pushNotify(msg)
    })
    .catch(err => {
      store.sending = false
      let error = err?.response?.data?.errors?.full_messages?.join('\n') || err?.message
      pushError(error)
    })
    .finally(() => {
      store.sending = false
    })
}
</script>
